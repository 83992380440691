<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-form-group :label="$t('name')">
                    <b-input-group class="mb-1" >
                        <div class="label-as-input">{{ isNotNullValue(formData.name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
         
        </b-row>
   
    </div>
</template>

<script>
    // Services
    import ProgramGroupsService from '@/services/ProgramGroupsService';

    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: {},
                formLoading: false
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ProgramGroupsService.get(id)
                              .then(response => {
                                  this.formData = response.data.data;
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
            }
        }
    }
</script>
